.tree-view-main-div {
  /* width: 25%; */
  border: 1px solid #dcedff !important;
  text-align: left;
  /* padding: 8px !important; */
  height: 100%;
  border-radius: 5px;
}

.metrics-view-main-div {
  width: 25%;
  border-top: 1px solid #dcedff !important;
  border-right: 1px solid #dcedff !important;
  text-align: left;
  /* padding: 8px !important; */
  height: 100%;
  /* border-radius: 5px; */
}

.tree-view-header {
  border-bottom: 1px solid #dcedff;
  text-align: left;
  padding: 8px;
  height: 100%;
}

.metrics-view-header {
  border-bottom: 1px solid var(--Secondary-text, #9aa0a9);
  text-align: left;
  /* padding: 8px; */
  height: 100%;
}

/* ul,
li {
  list-style: none;
  margin: 0;
  padding: 0;
} */

.tree-div {
  padding-left: 2px;
}

.tree-content-div {
  padding: 8px;
}

.tree-div-1 {
  padding-left: 1em;
  /* border: 1px dotted black; */
  border-width: 0 0 1px 1px;
}

.expand-icon {
  padding: 2px;
  cursor: pointer;
}

.custom-tooltip {
  background-color: #333;
  color: #fff;
}

.metric-rule-view {
  border-radius: 4px;
  background: #ebf3ff;
  margin: 10px;
  padding: 10px;
}

.metrics-rule-view-div {
  display: flex;
  gap: 10px;
  padding-top: 14px;
  flex-wrap: wrap;
}

.metrics-rule-view-span {
  border-radius: 20px;
  background: var(--bright_blue, #2e6ad2);
  display: inline-flex;
  padding: 5px 10px;
  align-items: center;
  gap: 4px;
  color: #fff;
}

.header-span {
  color: var(--Unnamed-Color, #003870);
  font-family: Source Sans Pro;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

/* 
li.container {
  border-bottom: 0px;
}

li.empty {
  font-style: italic;
  color: silver;
  border-color: silver;
}

p {
  margin: 0;
  background: white;
  position: relative;
  top: 0.5em;

}

p:before {
  content: '';
  position: absolute;
  width: 1em;
  border: 1px solid black;
  height: 1em;
  border-radius: 1em;
  margin-left: 0.5em;
}

r {
  margin-left: 2em;
}

ul {
  border-top: 1px dotted black;
  margin-left: -1em;
  padding-left: 2em;
}

ul li:last-child ul {
  border-left: 1px solid white;
  margin-left: -17px;
} */
