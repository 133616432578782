.custom-modal .modal-body {
  padding-top: 20px !important;
}

.custom-modal .status-model-footer {
  justify-content: center !important;
}

.drop-down-list {
  margin-left: 18px;
}

.drop-down-list .rw-widget-input {
  border: 0.5px solid #e9ebee !important;
  box-shadow: none;
}

.drop-down-list .rw-input {
  font-size: 0.75rem;
  font-weight: 400;
  line-height: 1.5;
}

.drop-down-list .rw-placeholder {
  font-size: 0.75rem;
  font-weight: 400;
  line-height: 1.5;
  color: #73777a;
}

/* .status-custom-modal .modal-header {
    display: flex;
    justify-content: space-between;
} */
