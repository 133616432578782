.fc {
  margin: 0 auto;
  .fc-popover {
    z-index: 1050;
  }
}

.fc-daygrid-day-number {
  font-size: 18px;
}

.create-event-modal-header,
.show-event-modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 430px;
  height: 50px;
  border-bottom: 1px solid #c7c9cb;

  h2 {
    font-size: 20px;
    color: #2c3957;
    font-weight: 600;
    font-family: "Source Sans Pro";
  }
}

.show-event-modal-header {
  width: 350px;
}

.published-event-container {
  width: 491px;
  height: 50px;
  justify-content: space-between;
  display: flex;
  align-items: center;
  padding: 0px 15px;
  background-color: rgba(254, 245, 235, 1);
  h2 {
    font-size: 18px;
    color: #2c3957;
    font-weight: 600;
    font-family: "Source Sans Pro";
  }
}
.web-create-event-title {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  p {
    font-family: "Source Sans Pro";
    font-style: normal;
    font-weight: 600;
    font-size: 12.64px;
    color: #555555;
  }
}
.title-input,
.title-input-error,
.description-input,
.description-input-error,
.webinar-link-input,
.webinar-link-input-error,
.date-input {
  width: 100%;
  margin-top: -14px;
  height: 36px;
  border: 1px solid #cccccc;
  border-radius: 4px;
  outline: none;
  padding-left: 5px;
  font-size: 13px;
}
.title-input-error,
.webinar-link-input-error {
  border: 1px solid #d43434;
}
.red-error-msg {
  color: #d43434;
  font-size: 12px;
}
.description-input {
  height: 65px;
}
.description-input-error {
  height: 65px;
  border: 1px solid #d43434 !important;
  padding: 0px !important;
}
.webinar-link-input {
  height: 36px;
  font-size: 12px;
  color: #5b87c4;
}

.date-input {
  display: flex;
  justify-content: space-between;
  padding-right: 5px;
  align-items: center;
}
.date-picker {
  width: 98%;
  height: 34px;
  outline: none;
  border: none;
}
.create-event-select-option {
  width: 100%;
  height: 36px;
  border: 0.5px solid #d9d9d9;
  border-radius: 4px;
  margin-top: -14px;
  padding: 0px 2px;
}

.start-end-time-div {
  width: 100%;
  height: 70px;
  display: flex;
  justify-content: space-between;

  .start-time,
  .end-time {
    width: 48%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    p {
      font-family: "Source Sans Pro";
      font-style: normal;
      font-weight: 600;
      font-size: 12.64px;
      color: #555555;
    }

    .select-date {
      width: 100%;
      margin-top: -10px;
      height: 36px;
      border: 0.5px solid #d9d9d9;
      border-radius: 4px;
    }
  }
}

.web-create-event-buttons {
  width: 100%;
  display: flex;
  justify-content: center;
}
.published-event-modal-btn {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}
.btn-cancel,
.btn-save-draft,
.btn-publish,
.btn-save-disabled {
  cursor: pointer;
  height: 32px;
  padding: 10px 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #ffffff;
  border: 1px solid #2e6ad2;
  border-radius: 2px;
  color: #2e6ad2;
  font-weight: 500;
  font-size: 14px;
}

.btn-save-draft,
.btn-save-disabled {
  background-color: #2e6ad2;
  color: #ffffff;
  border: none;
  padding: 10px 22px;
}

.btn-save-disabled {
  opacity: 0.8;
}
.btn-publish {
  background-color: #52a551;
  color: #ffffff;
  border: none;
}

.show-event-publish-modal-text {
  font-family: "Source Sans Pro";
  font-weight: bold;
  font-size: 16px;
  color: #949494;
  margin-left: 5px;
  margin-top: -1px;
}
.show-event-modal-text {
  font-family: "Source Sans Pro";
  font-weight: 400;
  font-size: 14px;
  color: #555555;
  margin-left: 5px;
  margin-top: -1px;
}

.show-event-modal-head-para {
  h3 {
    font-family: "Source Sans Pro";
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    color: #2c3957;
  }

  p {
    font-family: "Source Sans Pro";
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    color: #555555;
    margin-top: -2px;
  }
}

.hosted-text {
  font-weight: 600;
  font-size: 14px;
  color: #2c3957;
  margin-top: 1px;
  margin-left: 2px;
  margin-bottom: 3px;

  span {
    color: #5b87c4;
  }
}

.join-link-head {
  margin-top: 10px;
  font-weight: 600;
  font-size: 13px;
  color: #2c3957;
  margin-bottom: -2px;
}

.overflow-break-word {
  word-wrap: break-word;
}

.webinar-link-text {
  font-weight: 400;
  font-size: 12px;
  color: #5b87c4;
  cursor: pointer;
  word-wrap: break-word !important;
}
.webinar-link-text:hover {
  color: #0860f9;
}

.publishedEvent,
.draftEvent {
  height: 42px;
  background: rgba(99, 142, 202, 0.2);
  cursor: pointer;
  border: none !important;
  padding: 3px 5px !important;
  font-weight: 600 !important;
  border-radius: 2px;
  border-left: 4.5px solid #5b87c4 !important;
}
.draftPublishedEvent {
  height: 26px;
}
.publishedEvent:hover {
  background: rgba(92, 146, 220, 0.4);
}

.draftEvent {
  background: rgba(154, 160, 169, 0.2);
  border-left: 4.5px solid #555555 !important;
}
.draftEvent:hover {
  background: rgba(154, 160, 169, 0.4);
}

.fc-day {
  background-color: white !important;
}

.fc-daygrid-day-number,
.fc-col-header-cell-cushion {
  color: #6c6c6c !important;
  font-size: 14px !important;
}

.fc-day-today {
  background: #eef7ff !important;
  border: none !important;
}

// custom checkbox
$mapColor: ("green", #5eb37e, #fff), ("violet", #832da4, #fff),
  ("gray", #9aa0a9, #fff), ("skateblue", #5b87c4, #fff),
  ("yellow", #f19e37, #fff), ("blue", #3577ed, #fff), ("skyblue", #69c1fa, #fff),
  ("red", #d63864, #fff), ("pink", #ffa1b2, #fff);

.sb-checkbox {
  display: inline-block;
  margin-top: 4px;
  font-size: 0;
  &__input {
    display: none;
    &:checked {
      + .sb-checkbox__label {
        &:after {
          opacity: 1;
        }
      }
    }
  }
  &__label {
    display: inline-block;
    width: 15px;
    height: 15px;
    position: relative;
    cursor: pointer;
    &:before {
      content: "";
      width: 15px;
      height: 15px;
      border: 1.2px solid;
      box-sizing: border-box;
      display: inline-block;
      position: absolute;
      top: 0;
      left: 0;
      border-radius: 3px;
    }
    &:after {
      content: "done";
      font-family: "Material Icons";
      font-size: 12px;
      line-height: 15px;
      text-align: center;
      width: 15px;
      height: 15px;
      display: block;
      border-radius: 2px;
      overflow: hidden;
      text-align: center;
      opacity: 0;
      transition: 0.2s opacity;
    }
    @each $class, $color1, $color2 in $mapColor {
      &--#{$class} {
        &:before {
          border-color: $color1;
        }
        &:after {
          background-color: $color1;
          color: $color2;
        }
      }
    }
  }
}

.published-event-segment-text {
  font-family: "Source Sans Pro";
  font-weight: 600;
  font-size: 24px;
  color: #555555;
}

.published-event-segment-div {
  font-family: "Source Sans Pro";
  width: fit-content;
  height: 25px;
  padding: 2px 8px;
  border-radius: 20px;
  background-color: #3577ed;
  color: #fff;
  p {
    color: white;
    margin-top: 1px;
  }
}

.pub-cal-para-1,
.pub-cal-para-2 {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  margin-top: 5px;
  color: #000000;
}

.pub-cal-para-2 {
  margin-top: -8px;
  color: #555555;

  span {
    color: #2c3957;
    font-weight: 600;
    margin-left: 2px;
  }
}

.show-event-modal-btn {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.btn-edit,
.btn-delete {
  width: 80px;
  padding: 6px 0px;
  outline: none;
  border: none;
  background-color: #2e6ad2;
  color: #fff;
  border-radius: 2px;
  font-size: 14px;
  cursor: pointer;
}
.btn-edit:hover {
  background-color: #0860f9;
}
.btn-delete {
  background-color: #ff312b;
}
.btn-delete:hover {
  background-color: #f80b02;
}
.btn-edit-publish-calendar,
.btn-delete-publish-calendar {
  width: 50px;
  padding: 6px 0px;
  outline: none;
  border: none;
  background-color: transparent;
  color: #046cd9;
  border-radius: 2px;
  font-size: 16px;
  text-decoration: underline;
  cursor: pointer;
}

.btn-delete-publish-calendar {
  color: #ff0000;
}

@import "~rc-time-picker/assets/index.css";

.rc-time-picker-input {
  height: 35px;
  font-size: 14px;
}
.time-picker,
.time-picker-error {
  width: 100%;
  margin-top: -10px;
}
.time-picker-error {
  border: 1px solid #d43434;
  border-radius: 4px;
}

.fc-today-button {
  background-color: #2e6ad2 !important;
  border: none !important;
  cursor: pointer;
  width: 50px !important;
}

.mandatory {
  color: #bd2130;
}

.fc-event-main-frame {
  display: block !important;
}

.fc-event-time {
  font-weight: 500 !important;
  color: #000 !important;
  margin-bottom: -2px;
}

.fc-event-title {
  font-size: 12px !important;
  color: #000 !important;
}

.fc-daygrid-event-harness {
  padding: 2px 5px !important;
}

.calendar-header {
  display: flex;
  justify-content: space-between;
}

.calendar-header-left {
  width: 340px;
  display: flex;
  display: flex;
  justify-content: flex-start;
}

#calendar-title {
  font-size: 22px;
  font-weight: 600;
  width: fit-content;
  margin-left: 18px;
}

.calendar-header-middle {
  width: 220px;
  height: 34px;
  border-radius: 2px;
  border: 1px solid #e9ebed;
  padding: 3px;
  display: flex;
  justify-content: space-between;

  .calendar-btn,
  .calendar-btn-selected {
    height: 100%;
    width: 70px;
    border: none;
    outline: none;
    border-radius: 2px;
    background-color: transparent;
    color: #000000;
    font-weight: 600;
  }

  .calendar-btn-selected {
    background-color: #066bd9;
    color: #fff;
  }
}

.calendar-header-right {
  width: 320px;
  display: flex;
  justify-content: flex-end;
}

.calendar-custom-button {
  width: 33px;
  height: 32px;
  border-radius: 3px;
  background-color: #066bd9;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.calendar-custom-button:hover {
  background-color: #0479f6;
}

.prev-btn,
.next-btn {
  width: 26px;
  height: 26px;
  border-radius: 50%;
  background-color: #066bd9;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
}

.calendar-search-bar,
.activate-search-bar {
  width: 0px;
  height: 32px;
  border: 0.5px solid #b7b8b9;
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
  border-right: none;
  margin-right: -5px;
  transition: width 0.4s ease-in-out;
}

.activate-search-bar {
  width: 200px;
}
.search-input {
  height: 100%;
  width: 90%;
  outline: none;
  border: none;
  padding-left: 10px;
  background-color: inherit;
}

// Published Calendar Styles
.benchmarkEvent,
.marketLandscapeEvent,
.marketForecastEvent,
.specialReportEvent,
.companyReportEvent,
.ecosystemEvent,
.customerStudiesEvent {
  height: 26px;
  background: rgba(241, 158, 55, 0.1);
  cursor: pointer;
  border: none !important;
  padding: 3px 5px !important;
  font-weight: 600 !important;
  display: flex;
  align-items: center;
  border-radius: 2px;
  border-left: 4.5px solid #f19e37 !important;
  overflow: hidden !important;
}

.benchmarkEvent:hover {
  background: rgba(241, 158, 55, 0.3);
}

.marketLandscapeEvent {
  background: rgba(214, 56, 100, 0.1);
  border-left: 4.5px solid #d63864 !important;
}
.marketLandscapeEvent:hover {
  background: rgba(214, 56, 100, 0.3);
}

.marketForecastEvent {
  background: rgba(105, 193, 250, 0.1);
  border-left: 4.5px solid #69c1fa !important;
}
.marketForecastEvent:hover {
  background: rgba(105, 193, 250, 0.3);
}

.specialReportEvent {
  background: rgba(53, 119, 237, 0.1);
  border-left: 4.5px solid #3577ed !important;
}
.specialReportEvent:hover {
  background: rgba(53, 119, 237, 0.3);
}

.companyReportEvent {
  background: rgba(94, 179, 126, 0.1);
  border-left: 4.5px solid #5eb37e !important;
}
.companyReportEvent:hover {
  background: rgba(94, 179, 126, 0.3);
}

.customerStudiesEvent {
  background: rgba(131, 45, 164, 0.1);
  border-left: 4.5px solid #832da4 !important;
}
.customerStudiesEvent:hover {
  background: rgba(131, 45, 164, 0.3);
}

.ecosystemEvent {
  background: rgba(255, 161, 178, 0.2);
  border-left: 4.5px solid #ffa1b2 !important;
}
.ecosystemEvent:hover {
  background: rgba(255, 161, 178, 0.3);
}

.fc-daygrid-day {
  height: 120px !important;
}

.company-report-container,
.company-report-none {
  height: 90px;
  padding-left: 60px;
  display: block;
  transition: height 0.3s;
}

.company-report-none {
  height: 0px;
  display: hidden;
}

.specific-report,
.specific-report-none {
  margin-top: -5px;
  display: flex;
  visibility: visible;
}

.specific-report-none {
  visibility: collapse;
  transition: height 1s;
}

.select-placeholder {
  color: #9b9999;
}

.react-select-div {
  width: 100%;
  margin-top: -13px;
  font-size: 13px;
  // border: 1px solid red;
  // border-radius: 4px;
}

.bulk-upload-file-div {
  width: 100%;
  font-family: "Source Sans Pro";
  height: 124px;
  border: 1px dashed #ccc;
  border-radius: 2px;
  display: flex;
  flex-direction: column;
  background-color: #fbfbfb;
  justify-content: center;
  // align-items: center;

  p {
    color: #555555;
    font-size: 14.5px;
    text-align: center;
  }
}

.file-text {
  color: #555555;
  font-size: 14.5px;
  text-align: center;
}
.upload-btn {
  border: none;
  outline: none;
  background-color: #046cd9;
  padding: 7px 18px;
  border-radius: 3px;
  color: white;
  cursor: pointer;
  text-align: center;
  font-size: 13px;
}

.upload-btn:hover {
  background-color: #0461c5;
}

.uploaded-file {
  width: fit-content;
  padding: 5px 10px;
  border-radius: 25px;
  background-color: #046cd9;
  color: white;
  margin-bottom: 5px;
}

.fc-list-event {
  height: 35px !important;
}

.fc-list-event-graphic {
  background-color: transparent !important;
}

.fc-list-event-title {
  margin-left: 50px !important;
  height: 35px !important;
  border: none !important;
  outline: none !important;
  background-color: transparent !important;
}

.file-upload-success {
  width: 100%;
  padding: 8px;
  background-color: #10c06a;
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 2px;
  color: white;
}
.file-upload-error {
  margin-top: 20px;
  border-radius: 2px;
  background-color: #e74b3c;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 8px;

  .error-content {
    width: 100%;
    height: 20px;
  }
}

.error-dot {
  width: 7px;
  height: 7px;
  border-radius: 50%;
  border: 1px solid #fff;
  background-color: #fff;
  margin-top: 6px;
}
.file-error-text {
  color: white;
  margin-left: 5px;
}

.upload-file-icon {
  color: "#555555";
  cursor: pointer;
}

.published-calendar {
  .fc-timegrid-slot.fc-timegrid-slot-label.fc-scrollgrid-shrink,
  .fc-timegrid-slot-label-frame.fc-scrollgrid-shrink-frame,
  .fc .fc-timegrid-slot:empty::before,
  .fc-timegrid-slot.fc-timegrid-slot-lane {
    display: none;
  }
}

.fc-timegrid-col-events {
  width: 99% !important;
}

.published-event-bold-text {
  font-family: "Source Sans Pro";
  font-size: 14px;
  font-weight: bold;
  color: #555555;
}
.inner-publish-modal-div-bg {
  border-radius: 5px;
  padding: 3px;
  background-color: #f4f5f8;
}

.companyresearchModalHeader {
  background: rgba(94, 179, 126, 0.1);
}
.benchmarkModalHeader {
  background: rgba(241, 158, 55, 0.1);
}
.marketlandscapeModalHeader {
  background: rgba(214, 56, 100, 0.1);
}
.marketforecastModalHeader {
  background: rgba(105, 193, 250, 0.1);
}
.specialreportModalHeader {
  background: rgba(53, 119, 237, 0.1);
}
.customerstudyModalHeader {
  background: rgba(131, 45, 164, 0.1);
}
.ecosystemModalHeader {
  background: rgba(255, 161, 178, 0.2);
}

.draftCalendarEvent {
  height: 26px;
  background: rgba(99, 142, 202, 0.2);
  cursor: pointer;
  border: none !important;
  padding: 3px 5px !important;
  font-weight: 600 !important;
  border-radius: 2px;
  display: flex;
  align-items: center;
  border-left: 4.5px solid #555555 !important;
  .fc-list-event-dot {
    margin-left: 45px;
  }
  .fc-event-time {
    display: none !important;
  }
}
.draftCalendarModalHeaderEvent {
  background: rgba(154, 160, 169, 0.2);
}
.draftCalendarEvent:hover {
  background: rgba(154, 160, 169, 0.4);
}
