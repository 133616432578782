.add-category-model-div {
  display: flex;
  justify-content: flex-end;
}

.segment-dropdown-div {
  display: flex;
  width: 100%;
  gap: 15px;
}

.category-title {
  color: var(--navy-blue, #2c3957);
  font-family: Source Sans Pro;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 175%;
  /* 35px */
}

.level-title {
  color: var(--primary-text, #555);
  font-family: Source Sans Pro;
  font-size: 12.64px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.category-input {
  display: flex;
  flex-direction: column;
  margin-top: 15px;
}

.category-input span {
  color: var(--primary-text, #555);
  font-family: Source Sans Pro;
  font-size: 12.64px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.category-input input {
  border-radius: 4px;
  border: 0.5px solid #d9d9d9;
  height: 36px;
  width: 100%;
  padding: 12px;
  box-shadow: none;
}

.category-input-div {
  display: flex;
  width: 100%;
  gap: 8px;
  margin-top: 12px;
}

.custom-modal .add-category-model-footer {
  justify-content: space-between !important;
  margin-top: 15px;
}

.custom-modal .update-category-model-footer {
  justify-content: end !important;
  margin-top: 15px;
}

.add-category-model-footer .add-btn {
  border-color: #fff !important;
  padding: 0 !important;
  font-size: 12.64px !important;
  font-style: normal !important;
  font-weight: 600 !important;
  line-height: normal !important;
}

.modal-drop-down-list .rw-widget-input {
  box-shadow: none;
  border-radius: 4px;
  border: 0.5px solid #d9d9d9;
}

.modal-drop-down-list .rw-input {
  font-size: 0.75rem;
  font-weight: 400;
  line-height: 1.5;
}
