.modal-main-div {
  display: flex;
  flex-direction: column;
  gap: 20px;
  /* align-items: center; */
}

.download-modal-tab.nav-tabs .nav-link.active {
  color: #495057;
  background-color: #fff;
  border-bottom: none;
}

.tab-content-div {
  display: inline-flex;
  max-height: 287px;
  min-height: fit-content;
  flex-direction: column;
  align-items: flex-start;
  flex-shrink: 0;
  overflow: scroll;
}

.content-div {
  display: flex;
  width: 100%;
  padding: 5px 10px;
  align-items: center;
  gap: 10px;
  border-bottom: 1px solid #d6d8dd;
}

.content-div:hover {
  background-color: #dcedff !important;
}

.selected-div {
  display: flex;
  height: auto;
  flex-direction: column;
  align-items: flex-start;
  flex-shrink: 0;
  padding: 5px;
}

.select-main-div {
  padding: 10px;
  border-radius: 5px;
  background: #fff;
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25);
}

.selected-span {
  color: #000;
  font-family: Source Sans Pro;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.selected-span-div {
  display: flex;
  /* flex-wrap: wrap; */
  gap: 10px;
  overflow-y: scroll;
  width: 500px;
  padding: 10px 0px;
}

.selected-lable {
  display: flex;
  padding: 4px 8px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  flex-shrink: 0;
  border-radius: 20px;
  background: var(--bright_blue, #2e6ad2);
  color: var(--White, #fff);
  text-align: center;
  font-family: Source Sans Pro;
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.tab-div {
  display: flex;
  height: auto;
  align-items: flex-end;
  flex-shrink: 0;
  border-bottom: 1px solid #e9ecef;
  background: #fff;
  box-shadow: 0px 7px 20px 0px rgba(0, 0, 0, 0.08);
}

.select-rule-label {
  color: #12243e;
  font-family: Source Sans Pro;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 175%;
}

.step-2-select-div {
  display: flex;
  gap: 20px;
  width: 100%;
}

.custom-select-checkbox-dropdown .css-12a83d4-MultiValueRemove {
  display: none;
}

.custom-select-checkbox-dropdown .css-1ebjb75-multiValue {
  padding: 0;
  background: none !important;
  border: 0;
  min-width: max-content !important;
}

.custom-select-checkbox-dropdown .css-1ebjb75-multiValue > div {
  padding: 0;
}

.custom-select-checkbox-dropdown .css-1ebjb75-multiValue > div:after {
  content: ",";
}

.custom-select-checkbox-dropdown .css-3w2yfm-ValueContainer {
  height: 30px;
  width: max-content;
  flex-wrap: nowrap !important;
}

.custom-select-checkbox-dropdown
  .css-1ebjb75-multiValue:nth-last-child(2)
  > div:after {
  content: "" !important;
}

.custom-select-checkbox-dropdown .css-1ebjb75-multiValue > div + div {
  display: none;
}

.select-dropdown-label {
  color: #232d42;
  font-family: Source Sans Pro;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: 120%;
}

.downloading-loader {
  width: 8px;
  height: 48px;
  display: block;
  margin: auto;
  left: -50px;
  position: relative;
  border-radius: 4px;
  box-sizing: border-box;
  animation: animloader 1.5s linear infinite alternate;
}

@keyframes animloader {
  0%,
  100% {
    box-shadow: 10px 0 rgba(46, 106, 210, 0.1), 20px 0 rgba(46, 106, 210, 0.2),
      30px 0 rgba(46, 106, 210, 0.3), 40px 0 rgba(46, 106, 210, 0.4),
      50px 0 rgba(46, 106, 210, 0.5), 60px 0 rgba(46, 106, 210, 0.6),
      70px 0 rgba(46, 106, 210, 0.7), 80px 0 rgba(46, 106, 210, 0.8),
      90px 0 rgba(46, 106, 210, 0.9), 100px 0 rgb(46, 106, 210, 1);
  }

  50% {
    box-shadow: 10px 0 rgba(46, 106, 210, 1), 20px 0 rgba(46, 106, 210, 0.9),
      30px 0 rgba(46, 106, 210, 0.8), 40px 0 rgba(46, 106, 210, 0.7),
      50px 0 rgba(46, 106, 210, 0.6), 60px 0 rgba(46, 106, 210, 0.5),
      70px 0 rgba(46, 106, 210, 0.4), 80px 0 rgba(46, 106, 210, 0.3),
      90px 0 rgba(46, 106, 210, 0.2), 100px 0 rgba(46, 106, 210, 0.1);
  }
}

.downloadding-span {
  color: #212529;
  font-family: Source Sans Pro;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 120%;
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 16px;
}

.step4-div {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.step4-div img {
  height: 50px;
}

.downloadding-span-text {
  color: #606b77;
  font-family: Source Sans Pro;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 175%;
  /* 24.5px */
  width: 100%;
  display: flex;
  justify-content: center;
}

.upload-file-div {
  display: flex;
  width: 520px;
  height: 103px !important;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 5px;
  border-radius: 5px;
  border: 1px dashed #e0e0e0;
}

.quantdata-inserted-error-header {
  display: flex;
  width: 100%;
  padding: 5px 10px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 5px;
  background: #ffebea;
  height: 38px;
}

.quantdata-inserted-error-header span {
  color: #12243e;
  font-family: "Source Sans Pro";
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 175%;
  /* 28px */
}

.quantdata-inserted-header {
  display: flex;
  width: 100%;
  padding: 5px 10px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 5px;
  background: #e0f3e5;
  height: 38px;
}

.error-div {
  margin-top: 10px;
}

.error-div .error-div-count {
  color: #ea4335;
  font-family: "Source Sans Pro";
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
  /* 28.8px */
}

.error-div .error-div-massage {
  color: #232d42;
  font-family: "Source Sans Pro";
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 120%;
  margin-left: 5px;
}

.error-massage-div {
  margin-top: 10px;
  width: 100%;
  height: 36px;
  border-left: 4px solid #ea4335;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.error-massage-div span {
  color: #12243e;
  font-family: "Source Sans Pro";
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 175%;
  /* 28px */
  padding: 10px;
}
