.p-20 {
  padding: 20px;
}
.g-10 {
  gap: 10px;
}
.g-15 {
  gap: 15px;
}
.g-20 {
  gap: 20px;
}
.flex-d {
  flex-direction: column;
}
.justify-content-end {
  justify-content: flex-end;
}
.justify-content-space-between {
  justify-content: space-between;
}
.w-100 {
  width: 100%;
}
.h-100 {
  height: 100%;
}
.f-24 {
  font-size: 24px;
}
.div-1 {
  display: flex;
  justify-content: space-between;
  height: 35px;
  width: 100%;
  .tab-btn {
    display: flex;
    padding: 13px 34px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 5px;
    border: transparent;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 50px; /* 357.143% */
  }
  .active-tab {
    background: #b7ffe0;
    color: #000;
  }
  .not-active-tab {
    background: #f4f5f8;
    color: #555;
  }
  .tab-btn:focus {
    outline: #b7ffe0;
  }
  .add-resource-btn {
    padding: 3px 10px;
    color: var(--White, var(--color-gray-10, #fff));
    border-radius: 4px;
    border: 1px solid var(--bright_blue, #2e6ad2);
    background: var(--bright_blue, #2e6ad2);
    .icon {
      color: var(--White, var(--color-gray-10, #fff));
    }
  }
}

.modal-input-label {
  color: #555;
  font-family: "Source Sans Pro";
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px; /* 150% */
}

.modal-input {
  display: flex;
  padding: 10px 5px 10px 10px;
  align-items: center;
  border-radius: 4px;
  border: 0.5px solid #d9d9d9;
  background: var(--color-gray-10, #fff);
}

.error-text {
  color: #bd2130;
  font-family: "Source Sans Pro";
  font-size: 10px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px; /* 150% */
}

.grid-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  min-height: 250px;
  gap: 16px;
  padding: 15px 0px 0px 0px;
}

.grid-container > div {
  // background-color: lightgrey;
  padding: 15px;
  text-align: center;
}
.grid-item {
  display: flex;
  padding: 15px;
  flex-direction: column;
  align-items: flex-start;
  gap: 15px;
  flex: 1 0 0;
  border-radius: 10px;
  border: 1px solid #eaeaea;
  background: var(--color-gray-10, #fff);
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.09);
  position: relative;
  .thumbnail {
    height: 128px;
    width: 100%;
    border: 1px solid #e7e9ed;
    background: linear-gradient(
      0deg,
      rgba(0, 0, 0, 0.2) 0%,
      rgba(0, 0, 0, 0.2) 100%
    );
    opacity: 0.6;
  }
  .item-title {
    color: #161c2d;
    font-family: Montserrat;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    // line-height: 32px; /* 177.778% */
    letter-spacing: -0.5px;
  }
}
/* Switch.css */
.grid-switch {
  position: relative;
  display: inline-block;
  width: 36px;
  height: 18px;
}

.grid-switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.grid-slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  // background-color: #ccc;
  transition: 0.4s;
  border-radius: 34px;
}

.active-grid-slider:before {
  position: absolute;
  content: "";
  height: 16px;
  width: 16px;
  border-radius: 50%;
  left: -7px;
  bottom: 1px;
  background-color: #ffffff;
  transition: 0.4s;
}

.deactive-grid-slider:before {
  position: absolute;
  content: "";
  height: 16px;
  width: 16px;
  border-radius: 50%;
  left: 2.7px;
  bottom: 1px;
  background-color: #adb5bd;
  transition: 0.4s;
}

input:checked + label .grid-slider {
  background-color: #2196f3;
}

input:checked + label .grid-slider:before {
  transform: translateX(26px);
}
.align-item-center {
  align-items: center;
}
.play-button {
  position: absolute;
  top: 25%;
  left: 40%;
  z-index: 10;
}
// .faq-collapser-item {
//   border-top: 1px solid #d9d9d9;
//   margin-top: 15px;
// }

.faq-collapse {
  width: 100%;
  height: 36px;
  border-radius: 6px;
  background-color: #ffffff;
  display: flex;
  justify-content: space-between;
  align-items: center;

  align-items: center;
  gap: 32px;
  align-self: stretch;
  color: #000;
  .faq-collapse-header {
    gap: 32px;
    .span_1 {
      color: var(--Text-txt-tertiary, rgba(60, 60, 67, 0.5));
      font-family: Inter;
      font-size: 42px;
      font-style: normal;
      font-weight: 700;
      line-height: 120%; /* 50.4px */
      letter-spacing: 0.25px;
    }
    .span_2 {
      color: var(--Text-txt-primary, #000);
      font-family: Montserrat;
      font-size: 18px;
      font-style: normal;
      font-weight: 700;
      line-height: 120%; /* 21.6px */
    }
  }
}

.faq-collapse-list {
  color: var(--Text-txt-secondary, rgba(60, 60, 67, 0.85));
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 19.6px */
  align-self: stretch;
  margin-left: 75px;
  img {
    width: 100%;
  }
}
.active-modal-body-div {
  width: 330px;
  height: 100px;
  padding: 10px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  border-radius: 4px;
  text-align: center;
  font-family: "Source Sans Pro";
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  // line-height: 30px; /* 125% */
}
.report-dropdown-list {
  .rw-placeholder {
    color: #6d6d6d;
    font-size: 15px;
  }
}
.dropdown-list {
  .rw-widget-container {
    border-radius: 5px;
    border: 1px solid #e4e4e4;
  }
  .rw-widget-input {
    box-shadow: none;
  }
}
.youtube-player {
  border-radius: 10px;
  border: 1px solid #c9c7c7;
}

.jodit-status-bar {
  display: none !important;
}
