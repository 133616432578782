.rule-input-div input {
  display: flex;
  width: 304px;
  height: 35px;
  padding: 4px 10px;
  align-items: center;
  gap: 130px;
  flex-shrink: 0;
  border-radius: 5px;
  border: 1px solid #e4e4e4;
}

.create-btn-div {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
}

.create-btn {
  border-radius: 3px !important;
  background: #007bff !important;
  color: #fff !important;
  font-family: Source Sans Pro !important;
  font-size: 16px !important;
  font-style: normal !important;
  font-weight: 600 !important;
  line-height: normal !important;
}
